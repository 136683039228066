import {mapActions, mapGetters} from 'vuex'
import validationMixin from '@/mixins/validation'
import ChangePassword from '../../components/change-password/index.vue'
import ImageFile from '@/components/image-file/index.vue'
import {activityList, employerList, genderList} from '../../helpers'

export default {
    name: 'user-students-info',
    mixins: [validationMixin],
    data() {
        return {
            payload: {
                birthday: new Date().toISOString().substr(0, 10),
                activity: '',
                gender: '',
                avatar: '',
                fullName: '',
                email: '',
                typeId: 0,
                originCountry: '',
                defaultCountry: '',
                phone: '',
                isAuthor: false,
                isSpeaker: false,
                employers: '',
                isActive: false,
                interface: '',
                isTester: false
            },
            validationErrors: {},
            showPassword: false,
            valid: false,
            openDatepicker: false
        }
    },
    components: {
        ChangePassword,
        ImageFile
    },
    computed: {
        ...mapGetters({
            profile: 'profile/profile',
            accessCountries: 'profile/accessCountries',
            countries: 'setting/countries',
            interfaceLanguages: 'setting/interfaceLanguages',
            coursesList: 'setting/coursesList',
            currentStudent: 'userAdmins/currentStudent',
            updateImageLoading: 'userAdmins/updateImageLoading',
            currentStudentLoading: 'userAdmins/currentStudentLoading',
            isLoading: 'userAdmins/isLoading',
            userTypes: 'userSetting/userTypes',
        }),
        employerList,
        genderList,
        activityList,
        computedDateFormatted() {
            return this.formatDate(this.payload.birthday)
        },
        avatarObj() {
            if (!this.currentStudent) return null;

            return {
                id: this.currentStudent.id,
                src: this.payload.avatar
            }
        },
        interfaceLanguagesNames() {
            if (!!this.interfaceLanguages && this.interfaceLanguages.length) return this.interfaceLanguages.map(item => ({
                ...item,
                name: item.locale
            }))
        },
        idCountryDefault() {
            return this.profile.countries.data.find(el => el.type === 'default').country_id
        }
    },
    created() {
        if (this.$route.name === 'user.admins.personal-data') {
            this.fetchCurrentUser({id: this.$route.params.id}).then(() => {
                Object.assign(this.payload, this.currentStudent)
            })
        }
        if (!this.interfaceLanguages) {
            this.fetchInterface({id: this.idCountryDefault})
        }
        if (!this.countries) {
            this.fetchCountries()
        }
        if (!this.userTypes) {
            this.fetchUsersSetting()
        }
    },
    methods: {
        ...mapActions({
            fetchCountries: 'setting/GET_COUNTRIES_LIST',
            fetchInterface: 'setting/GET_LANGUAGE_INTERFACE_LIST',
            update: 'userAdmins/UPDATE_USER',
            updateImage: 'userAdmins/UPDATE_IMAGE',
            fetchCurrentUser: 'userAdmins/GET_LIST_ITEM',
            fetchUsersSetting: 'userSetting/GET_USERS_SETTING'
        }),
        updateImageLocal(params) {
            this.payload.avatar = params.get('src')
            this.submit()
        },
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        },
        getFormData() {
            const data = new FormData()
            data.append('id', this.$route.params.id)
            data.append('full_name', this.payload.fullName)
            data.append('email', this.payload.email)
            data.append('type_id', this.payload.typeId)
            data.append('phone', this.payload.phone)
            data.append('origin_country_id', this.payload.originCountry)
            data.append('default_country_id', this.payload.defaultCountry)
            data.append('is_author', this.payload.isAuthor ? '1' : '0')
            data.append('is_speaker', this.payload.isSpeaker ? '1' : '0')
            data.append('active', this.payload.isActive ? '1' : '0')
            data.append('avatar', this.payload.avatar)
            data.append('gender', this.payload.gender)
            data.append('birthday', this.payload.birthday)
            data.append('interface_language', this.payload.interface)
            data.append('activity[type]', this.payload.activity)
            data.append('activity[employees_count]', this.payload.employers)
            data.append('_method', 'patch')
            data.append('is_tester', this.payload.isTester ? '1' : '0')
            return data
        },
        submit() {
            this.$refs.form.validate()
            if (this.valid) {
                this.update(this.getFormData()).then(() => {
                    this.$toasted.success(this.$t('userSuccessUpdate'))
                }).catch(error => {
                    const errors = error.response.data.validation
                    for (const i in errors) {
                        errors[i].forEach(e => {
                            this.$toasted.error(e)
                        })
                    }
                })
            }
        },
        // changeDefaultCountriesValue () {
        //   this.fetchInterface({ id: this.payload.defaultCountry })
        // }
    }
}
