import { mapActions, mapGetters } from 'vuex'

const { Entropy } = require('entropy-string')
import validationMixin from '@/mixins/validation'

export default {
  name: 'user-change-password',
  mixins: [validationMixin],
  data () {
    return {
      payload: {
        password: '',
        confirmPassword: '',
        sendToEmail: false
      },
      valid: false,
      showPassword: false,
    }
  },
  computed: {
    ...mapGetters({
      updatePasswordLoading: 'user/updatePasswordLoading'
    })
  },
  methods: {
    ...mapActions({
      changePassword: 'user/UPDATE_USER_PASSWORD',
    }),
    generateRandomPassword () {
      const entropy = new Entropy({
        total: 1e6,
        risk: 1e9
      })
      const string = entropy.string()
      this.payload.password = string
      this.payload.confirmPassword = string
      this.payload.sendToEmail = true
    },
    resetFormData(){
      for(let i in this.payload){
        this.payload[i] = ''
      }
    },
    getFormData(){
      let data = new FormData();
      data.append('id', this.$route.params.id)
      data.append('password', this.payload.password);
      data.append('password_confirmation', this.payload.confirmPassword);
      data.append('send_password', this.payload.sendToEmail ? 1 : 0);
      data.append('_method', 'patch');
      return data
    },
    submit () {
      this.$refs.formPassword.validate();
      if (this.valid) {
        this.changePassword(this.getFormData()).then(() => {
          this.resetFormData();
          this.$refs.formPassword.reset();
          this.$toasted.success(this.$t('userSuccessChangePassword'))
        }).catch(error => {
          console.log(error)
        })
      }
    }
  },

}
